/**
 * Created by cpupower on 03.03.17.
 */
module gmtApp.restClientConfig {
    const moduleName = 'gmtApp.restClientConfig';

    export type RestConstants = {TOKEN_AUTH_URL: string,
        TOKEN_REFRESH_URL: string,
        TOKEN_VERIFY_URL: string,
        BASE_URL: string}

    angular.module(moduleName, [])
        .constant('REST_API', (function (): RestConstants {
            let server_base = 'https://gmt-fki.ostfalia.de';
            return {
                TOKEN_AUTH_URL: server_base + '/api-token-auth/',
                TOKEN_REFRESH_URL: server_base + '/api-token-refresh/',
                TOKEN_VERIFY_URL: server_base + '/api-token-verify/',
                BASE_URL: server_base + '/api/v2'
            }
        })());
}
